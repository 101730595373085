import { GraphQLClient } from "graphql-request"
import { type RequestMiddleware } from "graphql-request/build/esm/types"
import {
  type TRequest,
  graphqlClientMiddleware,
  FLAG_DIET_COOKIE,
  graphqlClientMiddlewareV2,
} from "shared-utils"

export const FEDERATED_TYK_API_URL =
  process.env.NEXT_PUBLIC_CMS_FEDERATED_TYK_URL ||
  process.env.NEXT_PUBLIC_FEDERATED_TYK_URL ||
  "https://gtp-tyk.eproc.dev/graphql/"

export const GRAPHQL_URL = FLAG_DIET_COOKIE ? `/graphql` : FEDERATED_TYK_API_URL

const middleware: RequestMiddleware = (request) => {
  const resultMiddleware = FLAG_DIET_COOKIE
    ? graphqlClientMiddlewareV2({
        request: request as TRequest,
        signOut: () => {
          return
        },
      })
    : graphqlClientMiddleware({
        request: request as TRequest,
        signOut: () => {
          return
        },
      })
  return resultMiddleware
}

export const federatedGqlClient = new GraphQLClient(GRAPHQL_URL, {
  requestMiddleware: middleware,
  credentials: "include",
})
